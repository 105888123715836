import { Injectable } from '@angular/core';
import { type Resource } from '../models/jsonapi/resource';
import { type ResourceCollection } from '../models/resource-collection';
import { type ResourceTypes } from '../models/resource.types';

@Injectable({
    providedIn: 'root',
})
export class ResourceCollectionBuilderService {
    public build(resources: Resource[]): ResourceCollection {
        const collection: ResourceCollection = {};

        resources.forEach((resource: Resource): void => {
            const type: ResourceTypes = resource.type as ResourceTypes;

            if (collection[type] === undefined) {
                collection[type] = {};
            }

            collection[type][resource.id] = resource;
        });

        return collection;
    }
}
