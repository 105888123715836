/* eslint-disable sonarjs/no-duplicate-string */
import { ResourceTypes } from './models/resource.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ResourceInclude: Partial<Record<ResourceTypes, string[]>> = {
    [ResourceTypes.ChatConfiguration]: [],
    [ResourceTypes.NodeAccordion]: [
        'big_accordion_items.big_accordion_item_body.list_items',
        'big_accordion_items.big_accordion_item_body.quicklinks_items.file',
        'big_accordion_items.big_accordion_item_body.quicklinks_items.node',
    ],
    [ResourceTypes.NodeAccordionItem]: [
        'big_accordion_item_body.list_items',
        'big_accordion_item_body.quicklinks_items.file',
        'big_accordion_item_body.quicklinks_items.node',
    ],
    [ResourceTypes.NodeBigArticle]: [
        'image_introduction',
        'content.image',
        'content.list_items',
        'content.quicklinks_items.file',
        'content.quicklinks_items.node',
        'content.links',
        'content.card_group_card.icon',
        'content.card_group_card.image',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.list_items',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.file',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.node',
        'content.searchable_link_lists.links',
    ],
    [ResourceTypes.NodeBigTemplate]: [
        'image_introduction',
        'content.image',
        'content.list_items',
        'content.quicklinks_items.file',
        'content.quicklinks_items.node',
        'content.links',
        'content.card_group_card.icon',
        'content.card_group_card.image',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.list_items',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.file',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.node',
        'content.searchable_link_lists.links',
    ],
    [ResourceTypes.NodeBigFormOverview]: ['content'],
    [ResourceTypes.NodeBigMyBig]: [
        'content.accordion_node.big_accordion_items.big_accordion_item_body.list_items',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.file',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.node',
        'content.card_group_card.icon',
        'content.card_group_card.image',
        'content.items',
        'content.links',
        'content.quicklinks_items.file',
        'content.quicklinks_items.node',
    ],
    [ResourceTypes.NodeBigPersonalData]: ['content.paragraphs'],
    [ResourceTypes.NodeBigStartPage]: [
        'image_introduction',
        'content.image',
        'content.card_group_card.icon',
        'content.card_group_card.image',
        'content.filterable_link_lists.links',
        'content.list_items',
        'content.links',
    ],
    [ResourceTypes.NodeBigTaggedArticle]: [
        'image_introduction',
        'content.image',
        'content.list_items',
        'content.quicklinks_items.file',
        'content.quicklinks_items.node',
        'content.links',
        'content.card_group_card.icon',
        'content.card_group_card.image',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.list_items',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.file',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.node',
    ],
    [ResourceTypes.NodeBigTaggedArticleOverview]: ['image_introduction', 'content.card_group_card.image'],
    [ResourceTypes.NodeDashboard]: ['content.links', 'content.customer_segment', 'content.card_group_card.icon', 'content.card_group_card.image'],
    [ResourceTypes.NodeError]: ['image_introduction', 'content'],
    [ResourceTypes.NodeForm]: [
        'content',
        'content.image',
        'content.card_group_card.icon',
        'content.card_group_card.image',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.list_items',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.file',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.node',
    ],
    [ResourceTypes.NodeBigEpq]: ['content', 'content.quicklinks_items.file', 'content.quicklinks_items.node'],
    [ResourceTypes.NodeDPPArticle]: [
        'image_introduction',
        'content.image',
        'content.list_items',
        'content.quicklinks_items.file',
        'content.quicklinks_items.node',
        'content.links',
        'content.card_group_card.icon',
        'content.card_group_card.image',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.list_items',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.file',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.node',
        'content.searchable_link_lists.links',
        'content.paragraphs.card_group_card.icon',
        'content.paragraphs.card_group_card.image',
        'content.paragraphs.list_items',
    ],
    [ResourceTypes.NodeDPPPrivateArea]: [
        'content.badge.image',
        'content.card_group_card.icon',
        'content.card_group_card.image',
        'content.grouped_link_lists.download_list.grouped_link_lists_file.pdf_key',
        'content.icon',
        'content.image',
        'content.items',
        'content.list_items',
        'content.quicklinks_items.file',
        'content.quicklinks_items.node',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.list_items',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.file',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.node',
    ],
    [ResourceTypes.NodeDPPStartPage]: [
        'content.badge.image',
        'content.card_group_card.icon',
        'content.card_group_card.image',
        'content.image',
        'image_introduction',
    ],
    [ResourceTypes.NodeLegal]: [
        'content',
        'content.list_items',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.list_items',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.file',
        'content.accordion_node.big_accordion_items.big_accordion_item_body.quicklinks_items.node',
    ],
    [ResourceTypes.NodeBigNews]: [
        'image_introduction',
        'news_image_introduction',
        'content.image',
        'content.list_items',
        'content.quicklinks_items.file',
        'content.quicklinks_items.node',
        'content.card_group_card.icon',
        'content.card_group_card.image',
    ],
    [ResourceTypes.TaxonomyTermDPPKeyAccountManager]: [
        'image',
    ],
};
