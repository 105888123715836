import { inject, Injectable } from '@angular/core';
import { PDFFileModel } from '@big-direkt/utils/shared';
import { ResourceCollection } from '../../models/resource-collection';
import { MediaFileIdentifier } from '../../models/resource-identifiers/media-file.identifier';
import { ResourceTypes } from '../../models/resource.types';
import { MediaFileResource } from '../../models/resources';
import { JsonApiResourceMapper } from '../json-api-resource-mapper.decorator';
import { ResourceIdentifierMapperService } from '../resource-identifier-mapper.service';
import { ResourceMapper } from './resource.mapper';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class MediaFileMapper extends ResourceMapper<MediaFileIdentifier> {
    public static override readonly type: ResourceTypes = ResourceTypes.MediaFile;

    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: MediaFileResource, collection: ResourceCollection): MediaFileIdentifier {
        const fileInfo = resource.meta;

        return {
            type: ResourceTypes.MediaFile,
            id: resource.id,
            pdfFile: this.resourceIdentifierMapper.map<PDFFileModel>(resource.relationships.pdf_key.data ?? undefined, collection),
            meta: {
                title: fileInfo.title,
                uri: fileInfo.uri,
                size: fileInfo.size,
                type: fileInfo.type,
                text: resource.attributes?.media_text ?? undefined,
            },
        };
    }
}
