import { CdkAccordionModule } from '@angular/cdk/accordion';
import { Component, HostBinding, Input, type OnInit } from '@angular/core';

@Component({
    selector: 'big-ui-accordion',
    templateUrl: './accordion.component.html',
    standalone: true,
    imports: [CdkAccordionModule],
})
export class AccordionComponent implements OnInit {
    private readonly archClasses =
        // eslint-disable-next-line max-len
        "min-h-[220px] before:absolute before:left-0 before:w-full before:h-[220px] before:bg-[url('/assets/bogen.svg')] before:bg-no-repeat before:bg-bottom before:bg-[length:100%_220px]";

    @Input() public showArch = false;
    @HostBinding('class') @Input() public classList = 'block';

    public ngOnInit(): void {
        if (this.showArch) {
            this.classList = [this.classList, this.archClasses].join(' ');
        }
    }
}
