import { Injectable } from '@angular/core';
import { type Maybe } from '@big-direkt/utils/shared';
import { type Resource } from '../models/jsonapi/resource';
import { type ResourceIdentifier } from '../models/jsonapi/resource-identifier';
import { type ResourceCollection } from '../models/resource-collection';
import { type ResourceTypes } from '../models/resource.types';
import { MapperInjectorService } from './mapper-injector.service';
import { type ResourceMapper } from './resource/resource.mapper';

@Injectable({
    providedIn: 'root',
})
export class ResourceIdentifierMapperService {
    public constructor(protected readonly mapperInjector: MapperInjectorService) {}

    public map<T>(identifier: ResourceIdentifier | undefined, collection: ResourceCollection): Maybe<T> {
        if (!identifier) {
            return undefined;
        }

        const resource: Resource | undefined = collection[identifier.type as ResourceTypes]?.[identifier.id];

        if (!resource) {
            console.warn(`Resource not found -> type: '${identifier.type}', id: '${identifier.id}'`);

            return undefined;
        }

        ResourceIdentifierMapperService.mergeMeta(resource, identifier);

        try {
            const mapper: ResourceMapper<T> = this.mapperInjector.get(identifier.type as ResourceTypes);

            return mapper.map(resource, collection);
        } catch (error: unknown) {
            // TODO: use helper or service, to log / display errors of type unknown
            let errorMessage = `mapperInjector can not get ${identifier.type}`;

            if (error instanceof Error) {
                errorMessage = error.message;
            }

            console.warn(errorMessage);
        }

        return undefined;
    }

    public mapMultiple<T>(identifiers: (ResourceIdentifier | undefined)[] | undefined, collection: ResourceCollection): Maybe<T[]> {
        if (!identifiers) {
            return [];
        }

        return identifiers.reduce((result: T[], identifier: ResourceIdentifier | undefined): T[] => {
            const item: Maybe<T> = this.map(identifier, collection);

            if (item !== undefined) {
                result.push(item);
            }

            return result;
        }, []);
    }

    private static mergeMeta(resource: Resource, identifier: ResourceIdentifier): void {
        if (!identifier.meta) {
            return;
        }

        if (!resource.meta) {
            resource.meta = identifier.meta;

            return;
        }

        // The identifier meta object has higher priority and will override resource meta object if they have same keys
        // Reason is that the identifier meta may be defined with the field enhancer in the backend
        resource.meta = {
            ...resource.meta,
            ...identifier.meta,
        };
    }
}
