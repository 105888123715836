import { Injectable } from '@angular/core';
import { type ServiceToolsModel } from '@big-direkt/utils/shared';
import { ResourceTypes } from '../../models/resource.types';
import { JsonApiResourceMapper } from '../json-api-resource-mapper.decorator';
import { type TaxonomyTermServiceTooolsResource } from './../../models/resources/taxonomy-term-service-tools.resource';
import { ResourceMapper } from './resource.mapper';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class TaxonomyTermServiceToolsMapper extends ResourceMapper<ServiceToolsModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.TaxonomyTermServiceTools;

    public map(resource: TaxonomyTermServiceTooolsResource): ServiceToolsModel {
        return {
            name: resource.attributes.name,
            replacement: resource.attributes.replacement,
        };
    }
}
