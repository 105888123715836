import { type Maybe } from '@big-direkt/utils/shared';
import { type Resource } from '../../models/jsonapi/resource';
import { type ResourceCollection } from '../../models/resource-collection';
import { type ResourceTypes } from '../../models/resource.types';

export abstract class ResourceMapper<T> {
    public static readonly type: ResourceTypes;

    public abstract map(resource: Resource, collection: ResourceCollection): Maybe<T>;
}
