import { Injectable, inject } from '@angular/core';
import { DPPKeyAccountManagerModel } from '@big-direkt/utils/shared';
import { ResourceCollection } from '../../models/resource-collection';
import { ResourceTypes } from '../../models/resource.types';
import { TaxonomyTermDPPKeyAccountManagerResource } from '../../models/resources/taxonomy-term-dpp-key-account-manager.resource';
import { JsonApiResourceMapper } from '../json-api-resource-mapper.decorator';
import { ResourceIdentifierMapperService } from '../resource-identifier-mapper.service';
import { ResourceMapper } from './resource.mapper';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class TaxonomyTermDPPKeyAccountManagerMapper extends ResourceMapper<DPPKeyAccountManagerModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.TaxonomyTermDPPKeyAccountManager;

    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: TaxonomyTermDPPKeyAccountManagerResource, collection: ResourceCollection): DPPKeyAccountManagerModel {
        const taxonomy: DPPKeyAccountManagerModel = {
            address: resource.attributes.address ?? undefined,
            email: resource.attributes.email,
            firstname: resource.attributes.firstname,
            lastname: resource.attributes.lastname,
            mobilePhone: resource.attributes.mobile_phone ?? undefined,
            phone: resource.attributes.phone ?? undefined,
        };

        if (resource.relationships.image.data) {
            taxonomy.image = this.resourceIdentifierMapper.map(resource.relationships.image.data, collection);
        }

        return taxonomy;
    }
}
