import { Injectable } from '@angular/core';
import { type PictureModel, type SourceModel } from '@big-direkt/utils/shared';
import { type ImageSource } from '../../models/resource-identifiers/media-image.identifier';
import { ResourceTypes } from '../../models/resource.types';
import { type MediaImageResource } from '../../models/resources';
import { JsonApiResourceMapper } from '../json-api-resource-mapper.decorator';
import { ResourceMapper } from './resource.mapper';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class MediaImageMapper extends ResourceMapper<PictureModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.MediaImage;

    public map(resource: MediaImageResource): PictureModel {
        const breakpoints: string[] = Object.keys(resource.meta.styles);

        const model: PictureModel = {
            defaultSourceUrl: resource.meta.thumbnail,
            altText: resource.meta.alt,
            downloadUrl: resource.meta.download_url,
            sources: breakpoints.map((breakpoint: string): SourceModel => {
                const styles: string[] = resource.meta.styles[breakpoint]
                    .map((item: ImageSource): string => `${item.url} ${item.multiplier}`);

                return {
                    url: styles.join(', '),
                    mediaQuery: breakpoint,
                };
            }),
        };

        if (!resource.meta.info) {
            return model;
        }

        model.info = {
            copyright: resource.meta.info.copyright,
            source: resource.meta.info.source,
            heading: resource.meta.info.heading,
            description: resource.meta.info.description,
        };

        return model;
    }
}
