import { Injectable } from '@angular/core';
import { PDFFileModel } from '@big-direkt/utils/shared';
import { ResourceTypes } from '../../models/resource.types';
import { TaxonomyTermPDFFileResource } from '../../models/resources/taxonomy-term-pdf-file.resource';
import { JsonApiResourceMapper } from '../json-api-resource-mapper.decorator';
import { ResourceMapper } from './resource.mapper';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class TaxonomyTermPDFFileMapper extends ResourceMapper<PDFFileModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.TaxonomyTermPDFFile;

    public map(resource: TaxonomyTermPDFFileResource): PDFFileModel {
        return {
            name: resource.attributes.name ?? '',
        };
    }
}
