import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { createStore, select, withProps } from '@ngneat/elf';

export interface MobileAppState {
    mobileApp: {
        isEmbeddedInMobileApp: boolean;
        version: string | undefined;
        operatingSystem: string | undefined;
        deviceModel: string | undefined;
        deactivateNativeFileUpload: boolean;
    }
}

export const initialState = {
    mobileApp: {
        isEmbeddedInMobileApp: false,
        version: undefined,
        operatingSystem: undefined,
        deviceModel: undefined,
        deactivateNativeFileUpload: false,
    },
};
export const mobileAppStore = createStore(
    { name: 'mobileApp' },
    withProps<MobileAppState>(initialState),
);

@Injectable({ providedIn: 'root' })
export class MobileAppRepository {
    public isEmbeddedInMobileApp = toSignal(mobileAppStore.pipe(
        select(state => state.mobileApp.isEmbeddedInMobileApp),
    ), { initialValue: false });

    public version = toSignal(mobileAppStore.pipe(
        select(state => state.mobileApp.version ?? ''),
    ), { initialValue: '' });

    public operatingSystem = toSignal(mobileAppStore.pipe(
        select(state => state.mobileApp.operatingSystem ?? ''),
    ), { initialValue: '' });

    public deviceModel = toSignal(mobileAppStore.pipe(
        select(state => state.mobileApp.deviceModel ?? ''),
    ), { initialValue: '' });

    public deactivateNativeFileUpload = toSignal(mobileAppStore.pipe(
        select(state => state.mobileApp.deactivateNativeFileUpload),
    ), { initialValue: false });

    public getValue(): MobileAppState['mobileApp'] {
        return mobileAppStore.getValue().mobileApp;
    }

    public setIsEmbeddedInMobileApp(isEmbeddedInMobileApp: MobileAppState['mobileApp']['isEmbeddedInMobileApp']): void {
        mobileAppStore.update(state => ({ mobileApp: { ...state.mobileApp, isEmbeddedInMobileApp } }));
    }
}
