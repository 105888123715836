import { Injectable, inject } from '@angular/core';
import { BadgeModel } from '@big-direkt/utils/shared';
import { ResourceCollection } from '../../models/resource-collection';
import { ResourceTypes } from '../../models/resource.types';
import { TaxonomyTermBadgeResource } from '../../models/resources/taxonomy-term-badge.resource';
import { JsonApiResourceMapper } from '../json-api-resource-mapper.decorator';
import { ResourceIdentifierMapperService } from '../resource-identifier-mapper.service';
import { ResourceMapper } from './resource.mapper';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class TaxonomyTermBadgeMapper extends ResourceMapper<BadgeModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.TaxonomyTermBadge;

    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: TaxonomyTermBadgeResource, collection: ResourceCollection): BadgeModel {
        return {
            name: resource.attributes.name ?? '',
            description: resource.attributes.description?.value ?? '',
            image: this.resourceIdentifierMapper.map(resource.relationships.image.data ?? undefined, collection) ?? undefined,
            link: resource.attributes.link ?? undefined,
        };
    }
}
