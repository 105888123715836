import { Injectable } from '@angular/core';
import { type MedicalAppointmentSubjectModel } from '@big-direkt/utils/shared';
import { type MedicalAppointmentSubjectsResource } from '../../models/resources/medical-appointment-subjects.resource';
import { ResourceTypes } from '../../models/resource.types';
import { JsonApiResourceMapper } from '../json-api-resource-mapper.decorator';
import { ResourceMapper } from './resource.mapper';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class MedicalAppointmentSubjectsMapper extends ResourceMapper<MedicalAppointmentSubjectModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.MedicalAppointmentSubject;

    public map(resource: MedicalAppointmentSubjectsResource): MedicalAppointmentSubjectModel {
        return {
            id: resource.id,
            value: resource.attributes.value,
        };
    }
}
