/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line no-shadow

export enum ResourceTypes {
    ChatConfiguration = 'big_chat--configuration',
    ImageCreditsResults = 'image-credits-results',
    MediaFile = 'media--file',
    MediaImage = 'media--image',
    MedicalAppointmentSubject = 'medical-appointment--subject',
    Menu = 'menu--menu',
    NodeAccordion = 'node--big_accordion',
    NodeAccordionItem = 'node--big_accordion_item',
    NodeBigArticle = 'node--big_article',
    NodeBigEpq = 'node--big_epq',
    NodeBigFormOverview = 'node--big_form_overview',
    NodeBigMyBig = 'node--big_mybig',
    NodeBigNews = 'node--big_news',
    NodeBigPersonalData = 'node--big_personal_data',
    NodeBigStartPage = 'node--big_start_page',
    NodeBigTaggedArticle = 'node--big_tagged_article',
    NodeBigTaggedArticleOverview = 'node--big_tagged_article_overview',
    NodeBigTemplate = 'node--big_template',
    NodeBigTopBar = 'node--big_top_bar',
    NodeDashboard = 'node--big_dashboard',
    NodeDPPArticle = 'node--dpp_article',
    NodeDPPPrivateArea = 'node--dpp_private_area',
    NodeDPPStartPage = 'node--dpp_start_page',
    NodeError = 'node--big_error',
    NodeForm = 'node--big_form',
    NodeLegal = 'node--big_legal',
    NodePublicDelivery = 'node--big_public_delivery',
    NodeQuickLink = 'node--big_quicklink',
    ParagraphAccordion = 'paragraph--big_accordion',
    ParagraphBigAliveButton = 'paragraph--big_alive_button',
    ParagraphBigAppliancesSearch = 'paragraph--big_appliances_search',
    ParagraphBigBackLink = 'paragraph--big_back_link',
    ParagraphBigBadges = 'paragraph--big_badges',
    ParagraphBigBgmButton = 'paragraph--big_bgm_button',
    ParagraphBigBigtionaer = 'paragraph--big_bigtionaer',
    ParagraphBigButton = 'paragraph--big_button',
    ParagraphBigC2aBanner = 'paragraph--big_call_to_action_banner',
    ParagraphBigCard = 'paragraph--big_card',
    ParagraphBigCardFilterable = 'paragraph--big_card_filterable',
    ParagraphBigCardGroup = 'paragraph--big_card_group',
    ParagraphBigChat = 'paragraph--big_chat',
    ParagraphBigCookiebot = 'paragraph--big_cookiebot',
    ParagraphBigCustomerSegmentLinks = 'paragraph--big_customer_segment_links',
    ParagraphBigDashboardBigtionaer = 'paragraph--big_dashboard_bigtionaer',
    ParagraphBigDownloadList = 'paragraph--big_download_list',
    ParagraphBigEpq = 'paragraph--big_epq',
    ParagraphBigFilterableCardGroup = 'paragraph--big_filterable_card_group',
    ParagraphBigFilterableLinkList = 'paragraph--big_filterable_link_list',
    ParagraphBigGapRemover = 'paragraph--big_gap_remover',
    ParagraphBigGroupedLinkLists = 'paragraph--big_grouped_link_lists',
    ParagraphBigHeading = 'paragraph--big_heading',
    ParagraphBigHealthInsuranceComparison = 'paragraph--big_health_insurance_comparison',
    ParagraphBigIframe = 'paragraph--big_iframe',
    ParagraphBigImage = 'paragraph--big_image',
    ParagraphBigImageCredits = 'paragraph--big_image_credits',
    ParagraphBigInsuranceServices = 'paragraph--big_insurance_services',
    ParagraphBigLink = 'paragraph--big_link',
    ParagraphBigLinkItem = 'paragraph--big_link_item',
    ParagraphBigLinkList = 'paragraph--big_link_list',
    ParagraphBigMyBigContactData = 'paragraph--big_mybig_contact_data',
    ParagraphBigMyBigGeneral = 'paragraph--big_mybig_general',
    ParagraphBigMyBigGeneralItem = 'paragraph--big_mybig_general_item',
    ParagraphBigMyBigInbox = 'paragraph--big_mybig_inbox',
    ParagraphBigMyBigMedicalAppointment = 'paragraph--big_mybig_medical_appointment',
    ParagraphBigMyBigPersonalData = 'paragraph--big_mybig_personal_data',
    ParagraphBigMyBigSickNotes = 'paragraph--big_mybig_sick_notes',
    ParagraphBigNewsTeaser = 'paragraph--big_news_teaser',
    ParagraphBigPublicDelivery = 'paragraph--big_public_delivery',
    ParagraphBigSearchableLinkList = 'paragraph--big_searchable_link_list',
    ParagraphBigSubjectAreas = 'paragraph--big_subject_areas',
    ParagraphBigUserData = 'paragraph--big_user_data',
    ParagraphBigUserDetails = 'paragraph--big_user_details',
    ParagraphBigUserDetailsItem = 'paragraph--big_user_details_item',
    ParagraphC2aSearch = 'paragraph--c2a_search',
    ParagraphCoBrowsingButton = 'paragraph--big_co_browsing_button',
    ParagraphDPPGroupedLinkListsCollector = 'paragraph--dpp_grouped_link_lists_collector',
    ParagraphDPPKeyAccountManager = 'paragraph--dpp_key_account_manager',
    ParagraphDPPKeyAccountManagerSearch = 'paragraph--dpp_key_account_manager_search',
    ParagraphDPPQuickLinkDownloadLink = 'paragraph--big_dpp_quicklink_download_link',
    ParagraphFormOverview = 'paragraph--big_form_overview',
    ParagraphInstruction = 'paragraph--big_instruction',
    ParagraphList = 'paragraph--big_list',
    ParagraphListItem = 'paragraph--big_list_item',
    ParagraphQuickLinkFile = 'paragraph--big_quicklink_file',
    ParagraphQuickLinkLink = 'paragraph--big_quicklink_link',
    ParagraphQuickLinkNode = 'paragraph--big_quicklink_node',
    ParagraphQuickLinks = 'paragraph--big_quicklinks',
    ParagraphSalesPerformance = 'paragraph--dpp_sales_performance',
    ParagraphServiceTool = 'paragraph--big_service_tool',
    ParagraphTable = 'paragraph--big_table',
    ParagraphText = 'paragraph--big_text',
    ParagraphTextButton = 'paragraph--big_text_button',
    ParagraphVideo = 'paragraph--big_video',
    ParagraphVideoGroup = 'paragraph--big_video_group',
    ParagraphWebform = 'paragraph--webform',
    Search = 'big_search--result',
    SearchItem = 'search-item',
    SearchSuggest = 'big_search--suggest',
    TaxonomyTermBadge = 'taxonomy_term--badge',
    TaxonomyTermBigIcons = 'taxonomy_term--big_icons',
    TaxonomyTermCustomerSegment = 'taxonomy_term--customer_segment',
    TaxonomyTermDPPKeyAccountManager = 'taxonomy_term--dpp_key_account_manager',
    TaxonomyTermPDFFile = 'taxonomy_term--pdf_file',
    TaxonomyTermServiceTools = 'taxonomy_term--service_tools',
}
