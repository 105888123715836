import { Injectable } from '@angular/core';
import { MenuItemType, type MenuItemModel, type MenuModel } from '@big-direkt/utils/shared';
import { type MenuItem, type MenuResource } from '../../models/resources/menu.resource';
import { ResourceTypes } from '../../models/resource.types';
import { JsonApiResourceMapper } from '../json-api-resource-mapper.decorator';
import { ResourceMapper } from './resource.mapper';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class MenuMapper extends ResourceMapper<MenuModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.Menu;

    public map(resource: MenuResource): MenuModel {
        return {
            label: resource.attributes.title,
            items: this.mapMultipleItems(resource.attributes.items),
        };
    }

    private mapMultipleItems(items: MenuItem[]): MenuItemModel[] {
        return items.map(this.mapSingleItem.bind(this));
    }

    private mapSingleItem(item: MenuItem): MenuItemModel {
        return {
            title: item.title,
            uri: item.url,
            icon: item.field_icon ?? undefined,
            iconOnly: item.field_icon_only === '1',
            children: item.children ? this.mapMultipleItems(item.children) : [],
            type: item.field_menu_type ?? MenuItemType.Default,
        };
    }
}
