<cdk-accordion-item
    #accordionItem="cdkAccordionItem"
    class="flex flex-col rounded-md shadow bg-default"
    [attr.id]="accordionItem.id"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'accordion-body-' + index()">
    <button
        (click)="itemClicked(accordionItem)"
        class="flex w-full px-6 py-4 mb-0 leading-6 h5"
        role="button">
        <span class="text-left break-words grow">{{ title() }}</span>
        <big-icon
                *transloco="let t; prefix: 'uiAccordion'"
            [icon]="accordionItem.expanded ? iconClose : iconPlus"
            class="my-auto ml-6 fill-primary"
            [attr.aria-label]="accordionItem.expanded ? t('close') : t('open')" />
    </button>
    <div
        role="region"
        class="block px-6 pb-2"
        [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + index()"
        [attr.aria-labelledby]="accordionItem.id">
        <ng-content ngProjectAs="[accordionItem]"></ng-content>
    </div>
</cdk-accordion-item>
