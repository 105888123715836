import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { type BigIconModel } from '@big-direkt/utils/shared';
import { ResourceTypes } from '../../models/resource.types';
import { type TaxonomyTermBigIconsResource } from '../../models/resources/taxonomy-term-big-icons.resource';
import { JsonApiResourceMapper } from '../json-api-resource-mapper.decorator';
import { ResourceMapper } from './resource.mapper';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class TaxonomyTermBigIconsMapper extends ResourceMapper<BigIconModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.TaxonomyTermBigIcons;

    public constructor(private readonly sanitizer: DomSanitizer) {
        super();
    }

    public map(resource: TaxonomyTermBigIconsResource): BigIconModel {
        return {
            name: resource.attributes.name,
            svg: {
                name: resource.attributes.svg_name,
                // eslint-disable-next-line sonarjs/no-angular-bypass-sanitization
                data: this.sanitizer.bypassSecurityTrustHtml(resource.attributes.svg_data),
            },
        };
    }
}
