import { Injectable, type InjectionToken, Injector } from '@angular/core';
import { type ResourceTypes } from '../models/resource.types';
import { type ResourceMapper } from './resource/resource.mapper';

@Injectable({
    providedIn: 'root',
})
export class MapperInjectorService {
    public static readonly tokens: Partial<Record<ResourceTypes, InjectionToken<ResourceMapper<unknown>>>> = {};

    public constructor(protected readonly injector: Injector) {}

    public get<T>(type: ResourceTypes): ResourceMapper<T> {
        const token: InjectionToken<ResourceMapper<T>> | undefined = MapperInjectorService.tokens[type];

        if (!token) {
            throw new Error(`Could not find injection token for resource type '${type}'`);
        }

        try {
            return this.injector.get(token);
        } catch {
            throw new Error(`Could not inject mapper for resource type '${type}'`);
        }
    }
}
