import { Injectable } from '@angular/core';
import { type Maybe } from '@big-direkt/utils/shared';
import { type Document } from '../models/jsonapi/document';
import { type Resource } from '../models/jsonapi/resource';
import { ResourceCollection } from '../models/resource-collection';
import { ResourceTypes } from '../models/resource.types';
import { MapperInjectorService } from './mapper-injector.service';
import { ResourceCollectionBuilderService } from './resource-collection-builder.service';
import { type ResourceMapper } from './resource/resource.mapper';

@Injectable({
    providedIn: 'root',
})
export class ResponseMapperService {
    public constructor(
        protected readonly mapperInjectorService: MapperInjectorService,
        private readonly collectionBuilder: ResourceCollectionBuilderService,
    ) {
    }

    public mapSingle<T>(document: Document): Maybe<T> {
        const data: Resource = document.data as Resource;
        const mapper: ResourceMapper<T> = this.mapperInjectorService.get(data.type as ResourceTypes);
        const included: ResourceCollection = document.included ? this.collectionBuilder.build(document.included) : {};

        return mapper.map(data, included);
    }

    public mapMultiple<T>(document: Document): T[] {
        const data: Resource[] = document.data as Resource[];

        if (data.length === 0) {
            return [];
        }

        const mapper: ResourceMapper<T> = this.mapperInjectorService.get(data[0].type as ResourceTypes);
        const included: ResourceCollection = document.included ? this.collectionBuilder.build(document.included) : {};

        return data.map((item: Resource) => mapper.map(item, included) as T);
    }
}
