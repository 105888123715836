import { ResourceTypes } from './models/resource.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ResourcePath: Partial<Record<ResourceTypes, string>> = {
    [ResourceTypes.ChatConfiguration]: 'chat/config',
    [ResourceTypes.MedicalAppointmentSubject]: 'medical-appointment/subjects',
    [ResourceTypes.Menu]: 'menu',
    [ResourceTypes.NodeAccordion]: 'node/big_accordion',
    [ResourceTypes.NodeAccordionItem]: 'node/big_accordion_item',
    [ResourceTypes.NodeBigArticle]: 'node/big_article',
    [ResourceTypes.NodeBigEpq]: 'node/big_epq',
    [ResourceTypes.NodeBigFormOverview]: 'node/big_form_overview',
    [ResourceTypes.NodeBigMyBig]: 'node/big_mybig',
    [ResourceTypes.NodeBigNews]: 'node/big_news',
    [ResourceTypes.NodeBigPersonalData]: 'node/big_personal_data',
    [ResourceTypes.NodeBigStartPage]: 'node/big_start_page',
    [ResourceTypes.NodeBigTaggedArticle]: 'node/big_tagged_article',
    [ResourceTypes.NodeBigTaggedArticleOverview]: 'node/big_tagged_article_overview',
    [ResourceTypes.NodeBigTemplate]: 'node/big_template',
    [ResourceTypes.NodeBigTopBar]: 'node/big_top_bar',
    [ResourceTypes.NodeDashboard]: 'node/big_dashboard',
    [ResourceTypes.NodeDPPArticle]: 'node/dpp_article',
    [ResourceTypes.NodeDPPPrivateArea]: 'node/dpp_private_area',
    [ResourceTypes.NodeDPPStartPage]: 'node/dpp_start_page',
    [ResourceTypes.NodeError]: 'node/big_error',
    [ResourceTypes.NodeForm]: 'node/big_form',
    [ResourceTypes.NodeLegal]: 'node/big_legal',
    [ResourceTypes.NodePublicDelivery]: 'node/big_public_delivery',
    [ResourceTypes.Search]: 'big_search/result',
    [ResourceTypes.SearchSuggest]: 'big_search/suggest',
    [ResourceTypes.TaxonomyTermDPPKeyAccountManager]: 'taxonomy_term/dpp_key_account_manager',
    [ResourceTypes.TaxonomyTermServiceTools]: 'taxonomy_term/service_tools',
};
